#unicrypt-menu-button {
  font-size: 14px;
  svg {
    margin-right: 3px;
    color: inherit !important;
  }
  .network-button {
    width: 120px;
  }
}
.unicrypt-menu-button-hover {
  z-index: 9;
}